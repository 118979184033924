import { useTranslation } from '@ubique-innovation/react-translations';
import includes from 'lodash/includes';
import React, { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as styles from './App.css';

import LoadingIndicator from '../components/LoadingIndicator';
import usePermissions from '../hooks/usePermissions';
import { Pathname } from '../types/Pathname';
import Company from './company/Company';
import Header from './header/Header';
import { themeClass } from './theme.css';
import Space from './workspace/Space';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ENV = process.env.REACT_APP_ENV! as string;

export const IDP_REDIRECT_PATH = ENV === 'PROD' ? '/signin-oidc' : '/signin-oidc';

const App = (): React.ReactElement => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const permissions = usePermissions();
    const [hasPermission, setHasPermission] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.signinRedirect();
    }

    useEffect(() => {
        if (permissions !== undefined) {
            setLoading(false);
            if (location.pathname === IDP_REDIRECT_PATH || location.pathname === '/') {
                if (includes(permissions, 'ADMIN')) {
                    navigate(Pathname.SPACE);
                } else if (includes(permissions, 'ABO_MANAGER')) {
                    navigate(Pathname.COMPANY);
                }
            }
            if (includes(permissions, 'ADMIN')) {
                setHasPermission(true);
            } else if (includes(permissions, 'ABO_MANAGER')) {
                setHasPermission(true);
            } else {
                setHasPermission(false);
            }
        }
    }, [location.pathname, navigate, permissions]);

    return (
        <div className={`${styles.app} ${themeClass}`}>
            {loading ? (
                <div className={styles.loadingIndicator}>
                    <LoadingIndicator />
                </div>
            ) : (
                <>
                    {!hasPermission ? (
                        <div className={styles.cont}>
                            <div className={styles.para}>{t('flesk.no.rights')}</div>
                            <button className={styles.btn} type="button" onClick={() => auth.signoutRedirect()}>
                                {t('navigation.logout')}
                            </button>
                        </div>
                    ) : (
                        <>
                            <Header />
                            <div className={styles.content}>
                                <Routes>
                                    <Route path={Pathname.SPACE} element={<Space />} />
                                    <Route path={Pathname.COMPANY} element={<Company />} />
                                </Routes>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default App;
